import React from "react";
import styled from "styled-components";
import {
  Link
} from "./index";

export const NavBrand = styled(Link)`
  display: flex;
  align-items: center;
`;

export default NavBrand;
