import React from 'react';
import styled from "styled-components";
import { hydrate } from "react-dom";
import lottie from "lottie-web";
import Layout from "../components/Layout"; import {
  Container,
  H1,
  H2,
  Link,
  LinkButton,
  breakpoints
} from "../components";

const StyledHero = styled.div`
  height: 320px;
`;

const HeroContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeroAnimation = styled.div`
  opacity: 0.1;
  transform: scale(3);
  z-index: -1;

  @media (min-width: ${breakpoints.md}) {
    transform: scale(2);
  }

  @media (min-width: ${breakpoints.lg}) {
    transform: scale(1);
  }
`

const HeroHeader = styled(H1)`
  color: ${props => props.theme.colors.textStrong};
  margin-top: -40px;
  position: absolute;
  width: 90%;
  transform: translateX(-50%);
  left: 50%;
`

class Hero extends React.Component {
  animationRef: React.RefObject<any> = React.createRef();

  componentDidMount() {
    const gearAnimation = lottie.loadAnimation({
      container: this.animationRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "animations/data.json"
    });
    gearAnimation.play();
  }

  render() {
    return (
      <StyledHero>
        <HeroContent>
          <HeroAnimation ref={this.animationRef}/>
          <HeroHeader>
            Creating virtual worlds through precision engineering.
          </HeroHeader>
        </HeroContent>
      </StyledHero>
    );
  }
}

const StyledContent = styled.div`
  padding: 32px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 100px 0;
  }
`;

const IllustrationFrame = styled.div<{
  side: "left" | "right"
}>`
  width: 100%;
  padding-bottom: 90%;
  background: #1B1B1B;
  border: 1px solid #F6F6F6;
  border-radius: 1px;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 0;
    height: 290px;
    width: 330px;
    ${props => props.side === "left"
        ? `
          box-shadow: 20px 20px 0px 0px rgba(37,37,37,1);
        `
        : `
          box-shadow: -20px 20px 0px 0px rgba(37,37,37,1);
        `
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 440px;
    width: 490px;
  }
`

const WhyGamesIllustration = styled.img`
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  height: 85%;

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
  }
`

const HypeIllustration = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  height: 75%;

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
  }
`

const BackersIllustration = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  height: 60%;

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
  }
`

const TeamIllustration = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  height: 85%;

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
  }
`

const TextContainer = styled.div<{
  side: "left" | "right"
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  height: 100%;

  @media (min-width: ${breakpoints.lg}) {
    ${props => props.side === "left"
        ? `
          padding-left: 80px;
          border-left: solid 2px #252525;
        `
        : `
          padding-right: 80px;
          border-right: solid 2px #252525;
        `
    }
  }
`;

const IllustrationContainer = styled.div<{
  side: "left" | "right"
}>`
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoints.md}) {
    justify-content: ${props => props.side === "left" ? "flex-start" : "flex-end"};
  }
`;

const FeaturedIllustration = styled.div`
  height: 90px;
  width: 90px;
  border-radius: 50px;
  border: solid 1px #F6F6F6;
  box-shadow: -10px 10px 0px 0px rgba(37,37,37,1);
  margin-bottom: 40px;
  position: relative
`;

const FeaturedTextContainer = styled.div`
  max-width: 780px;
  padding-top: 20px;
  padding-bottom: 36px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const FeaturedButton = styled(LinkButton)`
  margin-top: 20px;
  margin-right: 0px;
`;


const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-row-gap: 24px;
  column-gap: 50px;
  box-sizing: border-box;
  grid-template-columns: 100%;
  grid-template-areas:
    "our-focus"
    "why-games-illustration"
    "why-games-text"
    "more-than-hype-illustration"
    "more-than-hype-text"
    "decentralized-decision-making"
    "our-backers-illustration"
    "our-backers-text"
    "the-team-illustration"
    "the-team-text";

  @media (min-width: ${breakpoints.md}) {
    grid-row-gap: 100px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "our-focus our-focus"
      "why-games-text why-games-illustration"
      "more-than-hype-illustration more-than-hype-text"
      "decentralized-decision-making decentralized-decision-making"
      "our-backers-text our-backers-illustration"
      "the-team-illustration the-team-text";
  }
`;

const GridColumn = styled.div<{
  area: string
}>`
  grid-area: ${props => props.area}
`;

class Home extends React.Component {

  serverside = () => (
    typeof window === "undefined"
  );

  render() {
    return (
      <Layout
        hero={ <Hero/>}
        location="/"
      >
        <Container>
          <StyledContent>
            <Grid>
              <GridColumn area={"our-focus"}>
                <FeaturedTextContainer>
                  <H2>Our Focus</H2>
                  <p>We are a new venture backed game studio, which is focused on building massively-multiplayer “societal games” that are less focused on pure combat and instead encourage social interaction and cooperation between players. We aim to rekindle the social spirit of massively multiplayer games and foster creative collaboration in our virtual worlds.</p>
                </FeaturedTextContainer>
              </GridColumn>
              <GridColumn area={"why-games-text"}>
                <TextContainer side="left">
                  <H2>Why Games</H2>
                  <p>Games have the ability to bring people together from around the world to create experiences that we remember for our entire lives. They let us live out otherwise impossible experiences that range from building a farm to conquering worlds. They give us a reason to forge meaningful friendships with people we otherwise may never have spoken to.</p>
                </TextContainer>
              </GridColumn>
              <GridColumn area={"why-games-illustration"}>
                <IllustrationContainer side="right">
                  <IllustrationFrame side="right">
                    <WhyGamesIllustration src="/images/whygames-illustration.svg" />
                  </IllustrationFrame>
                </IllustrationContainer>
              </GridColumn>
              <GridColumn area={"more-than-hype-illustration"}>
                <IllustrationContainer side="left">
                  <IllustrationFrame side="left">
                    <HypeIllustration src="/images/morethanhype-illustration.svg" />
                  </IllustrationFrame>
                </IllustrationContainer>
              </GridColumn>
              <GridColumn area={"more-than-hype-text"}>
                <TextContainer side="right">
                  <H2>Art & Engineering</H2>
                  <p>Games bridge the gap between the worlds of art and engineering. Developing games presents some of the most challenging engineering problems in computer science and distributed systems, while also requiring us to imagine and craft new worlds and stories. Only through careful execution in all disciplines can we create a magical new experience for players.</p>
                </TextContainer>
              </GridColumn>
              <GridColumn area={"decentralized-decision-making"}>
                <FeaturedTextContainer>
                  <FeaturedIllustration>
                    <img
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%) translateX(-50%)",
                        left: "50%"
                      }}
                      src="/images/decentralizeddecisionmaking-illustration.svg"
                    />
                  </FeaturedIllustration>
                  <H2>Decentralized Decision Making</H2>
                  <p>There is no point in hiring smart people if you’re just going to tell them what to do. At Clockwork we recognize that employees should lead decision making on the products that they themselves are building. Small teams that own their own products and distributed decision making is at the core of our values.</p>
                  <FeaturedButton href="https://clockwork-labs.medium.com/how-can-startups-possibly-compete-cd2c7c9fdf89">LEARN MORE</FeaturedButton>
                </FeaturedTextContainer>
              </GridColumn>
              <GridColumn area={"our-backers-text"}>
                <TextContainer side="left">
                  <H2>Our Backers</H2>
                  <p>We are backed by an incredible group of investors including <b>Supercell, Firstminute Capital, Skycatcher, 1Up Ventures, and Supernode</b>. Our technology, talent, and focus on creating enormous sandbox worlds has also attracted industry leading angels such as, <b>Hilmar Pétursson, CEO of CCP Games (makers of EVE Online)</b> and <b>David Helgason, founder of Unity Technologies</b>.</p>
                </TextContainer>
              </GridColumn>
              <GridColumn area={"our-backers-illustration"}>
                <IllustrationContainer side="right">
                  <IllustrationFrame side="right">
                    <BackersIllustration src="/images/ourbackers-illustration.svg" />
                  </IllustrationFrame>
                </IllustrationContainer>
              </GridColumn>
              <GridColumn area={"the-team-illustration"}>
                <IllustrationContainer side="left">
                  <IllustrationFrame side="left">
                    <TeamIllustration src="/images/team-illustration.svg" />
                  </IllustrationFrame>
                </IllustrationContainer>
              </GridColumn>
              <GridColumn area={"the-team-text"}>
                <TextContainer side="right">
                  <H2>The Team</H2>
                  <p>We are industry professionals who have deep experience in building large scale distributed systems. The team is also growing quickly! We've attracted industry veterans from developers like Ubisoft and publishers like Kakao, who are inspired by our vision for games and the technology that we've developed to realize that vision.</p>
                </TextContainer>
              </GridColumn>
            </Grid>
          </StyledContent>
        </Container>
      </Layout>
    );
  }
};

export default Home;

declare global {
  interface Window { rootProps: any; }
}
if (typeof window !== "undefined") {
  hydrate(<Home {...window.rootProps} />, document.getElementById("app"));
}
