import React from "react";
import styled from "styled-components";
import {
  ListItem,
} from "./index";

const NavItem = styled(ListItem)`
  display: inline-block;
  padding: 0 20px;
  margin-bottom: 0px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`;

export default NavItem;
