import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import moment from "moment";
import Site from "./Site"
import NavBar from "./NavBar";
import NavItem from "./NavItem";
import NavLink from "./NavLink";
import NavBrand from "./NavBrand";
import { Container, GlobalStyle, Divider } from "./";
import theme from "./theme";

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
`;

// Probably can inherit from the Nav
const FooterNav = styled(NavBar)`
  height: 80px;
  width: 100%;
`;

const FooterSignature = styled.p`
  padding-top: 220px;
  padding-bottom: 150px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  color: ${props => props.theme.colors.textStrong};
  ${props => props.theme.font.footer}
`;

const FooterSocial = styled(Container)`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`;

const Logo = styled.img`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 150px;
`;

const StyledCopyright = styled.div`
  margin-bottom: 40px;
`;

const Overlay = styled.div<{ show: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.background};
  opacity: 0.9;
  z-index: 2;
  display: ${props => props.show ? "block" : "none"};
`

const StyledDropdownMenu = styled.div<{ show: boolean }>`
  position: fixed;
  right: 0;
  top: 0;
  width: 225px;
  height: 100%;
  background-color: ${props => props.theme.colors.menuBackground};
  display: ${props => props.show ? "block" : "none"};
  z-index: 3;
`

const CloseMenuButton = styled.div`
  text-align: right;
  padding-right: 20px;
  padding-top: 34px;
`

const MenuItem = styled.div`
  text-align: left;
  display: block;
  padding-bottom: 66px;
  padding-left: 40px;
`

const DropdownMenu = (props: { show: boolean, onClose: () => void}) => (
  <StyledDropdownMenu show={props.show}>
    <CloseMenuButton onClick={props.onClose}>
      <img src="/images/close-icon.svg"/>
    </CloseMenuButton>
    <Divider />
    <MenuItem>
      <NavLink href="/">Home</NavLink>
    </MenuItem>
    {/*
      <MenuItem>
        <NavLink href="/projects">Projects</NavLink>
      </MenuItem>
    */}
    <MenuItem>
      <NavLink href="/join">Join</NavLink>
    </MenuItem>
    <MenuItem>
      <NavLink href="https://clockwork-labs.medium.com/">Blog</NavLink>
    </MenuItem>
    <MenuItem>
      <NavLink href="/press">Press</NavLink>
    </MenuItem>
  </StyledDropdownMenu>
)

const Copyright = (props: any) => (
  <StyledCopyright>© {moment().year()} Clockwork Labs, Inc.</StyledCopyright>
);

const leftItems = [
  <NavItem>
    <NavBrand href="/">
      <img src="/images/brand.png" style={{height: "40px"}} />
    </NavBrand>
  </NavItem>
];

export class Layout extends React.PureComponent<{
  children?: any,
  hero?: React.ReactNode
  location: string,
  invertedHeader?: boolean
  announcement?: {
    abbreviated: string,
    detailed: string
  }
}> {

  state = {
    showDropdownMenu: false
  }

  render() {
    const rightItems: React.ReactNode[] = [
      /*
      <NavItem>
        <NavLink selected={this.props.location === "/projects"} href="/projects">Projects</NavLink>
      </NavItem>,
      */
      <NavItem>
        <NavLink selected={this.props.location === "/join"} href="/join">Join</NavLink>
      </NavItem>,
      <NavItem>
        <NavLink href="https://clockwork-labs.medium.com/">Blog</NavLink>
      </NavItem>,
      <NavItem>
        <NavLink selected={this.props.location === "/press"} href="/press">Press</NavLink>
      </NavItem>,
    ];

    const navMenu = (
      <NavItem onClick={() => this.setState({ showDropdownMenu: true })}>
        <img src="/images/menu-icon.svg" />
      </NavItem>
    )

    return (
      <ThemeProvider theme={theme}>
        <Site>
          <GlobalStyle />
          <Overlay show={this.state.showDropdownMenu}/>
          <DropdownMenu show={this.state.showDropdownMenu} onClose={() => this.setState({showDropdownMenu: false})}/>
          <Site.Header inverted={this.props.invertedHeader}>
            <NavBar
              navItemsLeft={leftItems}
              navItemsRight={rightItems}
              navMenu={navMenu}
            />
            {this.props.hero}
            <Site.HeaderBackground />
          </Site.Header>
          <Site.Content>
            {this.props.children}
          </Site.Content>
          <Site.Footer>
            <FooterContent>
              <Container>
                <FooterSignature>
                  Designed and engineered by Clockwork Labs.
                </FooterSignature>
                <Copyright />
                <Logo src="/images/logo.svg" />
              </Container>
            </FooterContent>
          </Site.Footer>
        </Site>
      </ThemeProvider>
    );
  };
}

export default Layout;
