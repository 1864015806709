import React from 'react';
import styled, { createGlobalStyle } from "styled-components";
import { matchPath, Route } from "react-router-dom"
import FaPlus from 'react-icons/lib/fa/plus';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.colors.backgroundInverted};
    margin: 0;
    text-rendering: optimizeLegibility;
    text-align: center;
    ${props => props.theme.font.body}
    color: ${props => props.theme.colors.text};
  }
`;

export const breakpoints = {
  xs: "0px",
  sm: "580px",
  md: "784px",
  lg: "1280px",
};

export const Container = styled.div`
  padding: 0 20px;

  @media (min-width: ${breakpoints.sm}) {
    max-width: ${breakpoints.sm};
    padding: 0 40px;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: ${breakpoints.md};
  }

  @media (min-width: ${breakpoints.lg}) {
    max-width: ${breakpoints.lg};
  }

  margin-left: auto;
  margin-right: auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormField = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

export const Input = styled.input`
  font-size: 16px;
  font-weight: 600;
  font-family: "Source Sans Pro";
  border: none;
  height: 42px;
  width: 280px;
  padding: 0 0 0 20px;
  margin-bottom: 10px;
  vertical-align: middle;
  display: inline-block;

  &:focus {
    outline: 0;
  }
`;


export const FormInput = styled(Input)`
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  padding: 20px;
  margin: auto;
  ${(props: any) => props.small ? "" : "height: 60px;"}
  font-size: 16px;
  letter-spacing: -0.4px;
  border: 1px solid #efefef;
  border-radius: 4px;
  width: 100%;
  max-width: 640px;
  &:focus-within {
    border: 1px solid ${props => props.theme.colors.text};
    outline: 0;
  }
`;


const FormButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  width: 100%;
  max-width: 640px;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.background};
  color: #576662;
  border: 1px solid #efefef;
  padding-right: 6px;

  &:focus-within {
    border: 1px solid #576662;
  }
`;

const FormButtonInternalInput = styled(Input)`
  background-color: transparent;
  padding: 0 20px 0 20px;
  color: #576662;
  width: 100%;
  font-size: 16px;
  letter-spacing: -0.4px;
  flex: 1;
  font-weight: 600;
  margin: 0;
  color: inherit;
  letter-spacing: -0.5px;
  border: none;

  &:focus {
    border: none;
    outline: none;
  }
`;

export const Link = styled.a`
  color: ${props => props.theme.colors.textLink};
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.textLinkHover};
    text-decoration: underline;
  }
  &:visited {
    color: ${props => props.theme.colors.textLinkVisited};
  }
  cursor: pointer;
`;

export const List = styled.ul`
  margin: 0 0 5%;
  padding: 0;
  letter-spacing: -0.4px;
  list-style-position: inside;
`;

export const ListItem = styled.li`
  margin-bottom: 5px;
`;

export const Divider = styled.div`
  height: 40px;
  width 100%;
`;

export const CardHeader = styled.div`
  padding: ${(props: any) => props.padding || "8px"};
  border-bottom: 1px solid rgba(50, 68, 63, 0.1);
  font-weight: bold;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .75rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12);
`;

export const CardBody = styled.div`
  padding: ${(props: any) => props.padding || "16px"};
`;

export const CardFooter = styled.div`
  padding: ${(props: any) => props.padding || "8px"};
  border-top: 1px solid rgba(50, 68, 63, 0.1);
  display: flex;
  justify-content: flex-end;
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

type GridProps = {
  columns?: number,
  rowGap?: string
};

const StyledGrid = styled.div<GridProps>`
  display: grid;
  width: 100%;
  grid-row-gap: ${props => props.rowGap || "0px"};
  grid-template-columns: repeat(${props => props.columns || 12}, [col-start] 1fr);
  box-sizing: border-box;
`;

const GridRow = styled.div`
  padding: 8px 0px;
`;

const GridColumn = styled.div<{
  width?: number,
}>`
  padding: 0px 8px;
  grid-column: auto / span ${props => props.width || 1};
`;

export class Grid extends React.Component<GridProps> {
  public static Row = GridRow;
  public static Column = GridColumn;

  render() {
    return (<StyledGrid {...this.props}>{this.props.children}</StyledGrid>)
  }
}

export const Button = styled.button<{
  raised?: boolean,
  small?: boolean,
  primary?: boolean,
  secondary?: boolean,
  negative?: boolean,
  inverted?: boolean,
  labeled?: "right" | "left",
}>`
  // font
  ${props => props.theme.font.button};
  color: ${props => props.inverted ? props.theme.colors.text : props.theme.colors.textInverted};
  background-color: ${props => props.inverted ? props.theme.colors.textInverted : props.theme.colors.textStrong};

  display: flex;
  align-items: center;

  margin: ${(props: any) => !props.noMargin ? "0 10px 0 0" : "0"};
  vertical-align: baseline;
  text-decoration: none;
  padding: ${props => props.small ? "6px 10px" : "12px 16px"};
  border-radius: ${props => props.small ? "16px" : "20px"};
  transition: all .1s;
  border: none;
  position: relative;
  cursor: pointer;

  &.labeled {
    padding-left: 40px;
  }

  .icon {
    position: absolute;
    left: 14px;
    top: 14px;
    box-sizing: border-box;
  }

  &:hover,:focus,:active {
    color: ${props => props.theme.colors.textStrong};
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 2px ${props => props.theme.colors.border};
  }

  ${props => props.raised ? "background-color: white; box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12)" : ""};

  ${
    props => {
      if (props.labeled == "right") {
        return `
          padding-left: 40px;
        `;
      }
      else if (props.labeled == "left") {
        return `
          padding-right: 48px;
        `;
      }
    }
  }

  ${
    props => {
      if (props.primary) {
        return `
          background-color: ${props.theme.colors.primary}
          color: ${props.theme.colors.textInvertedStrong};
        `;
      }
    }
  }

  ${
    props => {
      if (props.secondary) {
        return `
          background-color: ${props.theme.colors.secondary}
          color: ${props.theme.colors.textInvertedStrong};
        `;
      }
    }
  }

  ${
    props => {
      if (props.negative) {
        return `
          background-color: ${props.theme.colors.error}
          color: ${props.theme.colors.textInvertedStrong};
        `;
      }
    }
  }

  ${
    (props: any) => {
      if (props.inverted) {
        return `
          box-shadow: inset 0px 0px 0px 3px ${props.theme.colors.border};
          background-color: transparent;
          color: ${props.theme.colors.textInvertedStrong};
          &:hover,:focus,:active {
            box-shadow: 0 15px 35px rgba(0, 0, 0, 0.13), 0 0 5px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 3px ${props.theme.colors.border};
          }
        `;
      }
    }
  }

  ${
    props => {
      if (props.inverted && props.primary) {
        return `
          box-shadow: initial;
          background-image: linear-gradient(to bottom, #24c78e, #0eb293);
          color: ${props.theme.colors.textInvertedStrong};
          &:hover,:focus,:active {
            box-shadow: 0 15px 35px rgba(0, 0, 0, 0.13), 0 0 5px rgba(0, 0, 0, 0.05);
          }
        `;
      }
    }
  }

  ${
    (props: any) => {
      if (props.disabled) {
        return `
          opacity: .3;
        `;
      }
    }
  }

`;

export const Icon = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
`;

export const ButtonIcon = styled(Icon)`
  position: absolute;
  left: 14px;
  top: 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

export const LinkButton = Button.withComponent("a");

export const Header = styled.span`
`;

export const H1 = styled(Header.withComponent("h1"))`
  color: ${props => props.theme.colors.textStrong};
  ${props => props.theme.font.h1}
`;

export const H2 = styled(Header.withComponent("h2"))`
  ${props => props.theme.font.h2}
  color: ${props => props.theme.colors.textStrong};
  margin: 0.415em 0;
`;
