import { DefaultTheme, createGlobalStyle } from 'styled-components';

const theme: DefaultTheme = {
  font: {
    body: `
      font: 16px /25.6px "Roboto","Source Sans Pro",system-ui,-apple-system,sans-serif;
      letter-spacing: 0px;
    `,
    h1: `
      font: 28px /37px "Roboto","Source Sans Pro",system-ui,-apple-system,sans-serif;
      font-weight: bold;
      letter-spacing: 0px;
    `,
    h2: `
      font: 20px /26px "Roboto","Source Sans Pro",system-ui,-apple-system,sans-serif;
      font-weight: bold;
      letter-spacing: 0px;
    `,
    footer: `
      font: 20px /26px "Roboto","Source Sans Pro",system-ui,-apple-system,sans-serif;
      font-weight: 500;
      letter-spacing: 0px;
    `,
    navItem: `
      font: 15px /20px "Roboto","Source Sans Pro",system-ui,-apple-system,sans-serif;
      font-weight: 500;
      letter-spacing: 0px;
    `,
    button: `
      font: 12px /16px "Roboto","Source Sans Pro",system-ui,-apple-system,sans-serif;
      font-weight: bold;
      letter-spacing: 0px;
    `
  },
  colors: {
    // UI
    primary: "#24C78E",
    accent: "#EAFF68",
    secondary: "#0BA360",
    border: "#F6F6F6",
    borderWeak: "#EFEFEF",
    shadow: "#385f55",
    lines: "#525252",

    // Statuses
    success: "#FFFFFF",
    successInverted: "#2BC991",
    warning: "#B4B227",
    error: "#FF6D6D",

    // Background
    background: "#1B1B1B",
    backgroundInverted: "#222222",
    menuBackground: "#252525",

    // Text
    text: "#969696",
    textWeak: "#CACACA",
    textStrong: "#D8D8D8",
    textInverted: "#1B1B1B",
    textInvertedWeak: "#CACACA",
    textInvertedStrong: "#D8D8D8",
    textInvertedPrimary: "#D8D8D8",

    // Link
    textLink: "#5CA4FA",
    textLinkVisited: "#CACACA",
    textLinkHover: "#D8D8D8",

  }
};


export default theme;
