import React from "react";
import styled from "styled-components";
import {
  Container,
  List,
  ListItem,
  Link,
  breakpoints
} from "./index";
import NavItem from "./NavItem";
import NavBrand from "./NavBrand";

export const NavList = styled(List)`
  margin: 0;
  padding: 0;
  list-style-position: inside;
`;

export const NavItems = styled(NavList)`
  min-height: 40px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex: 1 0 auto;
  flex-direction: row;
`;

const StyledNav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  margin-top: 22px;
  color: ${props => props.theme.colors.text};

  @media (min-width: ${breakpoints.sm}) {
    padding: 20px 0px;
    margin-top: 40px;
  }
`;

const NavItemsLeft = styled(NavItems)``;

const NavItemsRight = styled(NavItems)`
  text-align: right;
  justify-content: flex-end;
  display: none;

  @media (min-width: ${breakpoints.sm}) {
    display: flex;
  }
`;

const NavMenu = styled(NavItems)`
  display: flex;
  flex: 0;

  @media (min-width: ${breakpoints.sm}) {
    margin-top: 40px;
    display: none;
  }
`;

export default (props: any) => {
  return (
    <Container className={props.className}>
      <StyledNav>
        <NavItemsLeft>
          {props.navItemsLeft}
        </NavItemsLeft>
        <NavItemsRight>
          {props.navItemsRight}
        </NavItemsRight>
        {
          props.navMenu
            ?
              <NavMenu>{props.navMenu}</NavMenu>
            :
              <div />
        }
      </StyledNav>
    </Container>
  );
};

