import React from "react";
import styled from "styled-components";

const StyledSite = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
`;

const Header = styled.header<{
  inverted?: boolean
}>`
  background-color: ${props => !props.inverted ? props.theme.colors.backgroundInverted : props.theme.colors.background};
  min-height: 40px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
`;

const Footer = styled.footer`
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.backgroundInverted};
  padding-top: 20px;
  flex-shrink: 0;
  z-index: 1;
`;

const HeaderGradient = styled.div`
  &:after {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -2;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(144deg, ${props => props.theme.colors.background} 0%, ${props => props.theme.colors.background} 95%);
  }
`;

const HeaderGradientTexture = styled.div`
  &:after {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(/images/noise-overlay.png);
  }
`;

const StyledHeaderBackground = styled.div`

`;

const HeaderBackground = (props: {children?: React.ReactNode}) => (
  <StyledHeaderBackground>
    {props.children}
  </StyledHeaderBackground>
);

export const Content = styled.div`
  flex: 1 0 auto;
  background-color: ${props => props.theme.colors.background};
  z-index: 0;
`;

export default class Site extends React.Component {
  public static HeaderBackground = HeaderBackground;
  public static Header = Header;
  public static Content = Content;
  public static Footer = Footer;

  render() {
    return (<StyledSite>{this.props.children}</StyledSite>)
  }
}
